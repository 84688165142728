import { ColumnLayout, Box } from '@cloudscape-design/components';
import { userManagerAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeviceGroupTable from 'components/device-group-manager/DeviceGroupTable';
import DeviceGroupTabs from 'components/device-group-manager/DeviceGroupTabs';
import Spinner from 'components/spinner/Spinner';
import { API_URL_PATH_GROUP_LIST } from 'constants/urls';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface AssetProps {
    assetCategory: string;
    assetId: string;
}

export interface DeviceGroup {
    assetAbsPaths: string[];
    assetPaths: string[];
    groupId: string;
    assets: AssetProps[];
    userRoleIds: string[];
    updatedAt: string;
    description: string;
    friendlyName: string;
}

const DeviceGroupsContext = createContext({
    allGroups: [] as DeviceGroup[],
    isLoading: false,
    isFetching: false,
    selectedGroup: [] as DeviceGroup[],
    setSelectedGroup: (state: DeviceGroup[]) => { },
    refetch: () => { },
});

export const useDeviceGroupsContext = () => useContext(DeviceGroupsContext);

const DeviceGroupListPage = () => {
    const [allGroups, setAllGroups] = useState<DeviceGroup[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<DeviceGroup[]>([]);
    const { setNotification } = usePageLayoutContext();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const locationState = location?.state;
        if (locationState?.action) {
            setNotification([
                {
                    type: 'success',
                    content: locationState.message,
                },
            ]);
        }

        navigate(location.pathname, {});
    }, []);

    const { data, isLoading, isFetching, status, refetch } = useFetchWithReactQuery({
        axiosInstance: userManagerAPI,
        url: API_URL_PATH_GROUP_LIST,
        key: 'device-group-list',
    });

    useEffect(() => {
        if (status === 'success') {
            setAllGroups(data?.items || []);
        }
    }, [status, data, refetch]);

    return (
        <DeviceGroupsContext.Provider
            value={{
                isLoading,
                isFetching,
                allGroups,
                selectedGroup,
                setSelectedGroup,
                refetch,
            }}
        >
            <ColumnLayout variant='text-grid'>
                {isLoading && (
                    <>
                        <br></br>
                        <Spinner />
                    </>
                )}
                {allGroups && <DeviceGroupTable />}
            </ColumnLayout>

            {allGroups && selectedGroup![0]?.groupId !== undefined && (
                <>
                    <Box margin={{ top: 'xxxl' }}></Box>
                    <ColumnLayout variant='text-grid'>
                        <DeviceGroupTabs />
                    </ColumnLayout>
                </>
            )}
        </DeviceGroupsContext.Provider>
    );
};

export default DeviceGroupListPage;
