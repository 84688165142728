import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    Header,
    SpaceBetween,
    StatusIndicator,
    Tiles,
} from '@cloudscape-design/components';

import Location from 'components/location';
import {
    API_URL_PATH_DM_DEVICE,
    URL_FULL_PATH_DEVICE_MANAGER_LIST,
} from 'constants/urls';
import { deviceManagerAPI } from 'api';

import useMutationWithReactQuery from 'hooks/useMutationWithReactQuery';
import { SelectedDevice, SelectedDevicesLocation } from 'types/custom';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

const InstallDevice = () => {
    const [selectedDevices, setSelectedDevice] = useState<SelectedDevice[]>([]);

    const [region, setRegion] = useState<OptionDefinition | null>(null);
    const [country, setCountry] = useState<OptionDefinition | null>(null);
    const [site, setSite] = useState<OptionDefinition | null>(null);
    const [building, setBuilding] = useState<OptionDefinition | null>(null);
    const [floor, setFloor] = useState<OptionDefinition | null>(null);
    const [room, setRoom] = useState<OptionDefinition | null>(null);
    const [flowType, setFlowType] = useState('install');

    const [disableFormFields, setDisableFormFields] = useState(false);
    const [deviceValidationError, setDeviceValidationError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.selectedDevices) {
            setSelectedDevice(location.state.selectedDevices);
        }
        if (location && location.state && location.state.flowType) {
            setFlowType(location.state.flowType);
        }
    }, [location]);

    const { mutate: installDevices, data: installData, isPending: isInstallPending } = useMutationWithReactQuery<{ 
        success: boolean, 
        message: string, 
        status?: string, 
        device: string 
    }[], SelectedDevicesLocation>({
        url: `${API_URL_PATH_DM_DEVICE}/${flowType}-bulk`,
        method: 'PATCH',
        api: deviceManagerAPI,
        onSuccess: () => {
            setDisableFormFields(true);
        },
        onError: (error) => {
            setDisableFormFields(false);
            setDeviceValidationError(error.message);
        }
    });

    const deviceOutcomes = Object.fromEntries(installData?.data?.map(current => [current.device, current]) || []);
    const allPassed = installData?.data.every(x => x.success);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (allPassed) {
            navigate(URL_FULL_PATH_DEVICE_MANAGER_LIST, {
                state: {
                    action: `${flowType}-device`,
                    status: 'success',
                    message: `Successfully installed ${installData?.data.length} ${installData?.data.length === 1 ? 'device.' : 'devices.'}` 
                },
            });
            return;
        }

        const isValid =
            region?.value &&
            country?.value &&
            site?.value &&
            building?.value &&
            floor?.value &&
            room?.value;

        if (!isValid) {
            setDeviceValidationError('Enter all required fields');
            return;
        }

        setDisableFormFields(true);
        setDeviceValidationError('');
        installDevices({
            devices: selectedDevices,
            location: {
                regionId: region.value || '',
                regionName: region.label || '',
                countryId: country.value || '',
                countryName: country.label || '',
                siteId: site.value || '',
                siteName: site.label || '',
                buildingId: building.value || '',
                buildingName: building.label || '',
                floorId: floor.value || '',
                floorName: floor.label || '',
                roomId: room.value || '',
                roomName: room.label || '',
            }
        })
    };

    return (
        <form onSubmit={handleSubmit} className='no-radio-controls'>
            <Form
                actions={
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            formAction='none'
                            variant='link'
                            onClick={() =>
                                navigate(URL_FULL_PATH_DEVICE_MANAGER_LIST, {})
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            loading={
                                isInstallPending
                            }
                        >
                            {allPassed ? 'Finish' : 'Submit'}
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <Header variant='h2' description=''>
                        Install devices
                    </Header>
                }
                errorText={
                    deviceValidationError
                }
            >
                <SpaceBetween direction='vertical' size='l'>
                    <Tiles
                        value={null}
                        columns={3}
                        items={selectedDevices.map(device => {
                            return {
                                "label": device.friendlyName,
                                "description": <div>
                                    {device.name}
                                    <br/>
                                    {deviceOutcomes?.[device.name] && (
                                        <StatusIndicator type={deviceOutcomes?.[device.name].success ? 'success' : 'error'}>
                                            {deviceOutcomes[device.name].message}
                                        </StatusIndicator>
                                    )}
                                </div>,
                                "value": device.name
                            }
                        })}
                    />

                    <Location
                        region={region}
                        country={country}
                        site={site}
                        building={building}
                        floor={floor}
                        room={room}
                        setRegion={setRegion}
                        setCountry={setCountry}
                        setSite={setSite}
                        setBuilding={setBuilding}
                        setFloor={setFloor}
                        setRoom={setRoom}
                        disableFormFields={disableFormFields}
                        deepLocations={true}
                    />
                </SpaceBetween>
            </Form>
        </form>
    );
};

export default InstallDevice;
