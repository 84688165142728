import { createContext, useContext, useEffect, useState } from 'react';
import { Box, ColumnLayout } from '@cloudscape-design/components';
import { useLocation, useNavigate } from 'react-router-dom';

import { usePageLayoutContext } from 'components/common/layout';
import DeviceTable from 'components/device-manager/DeviceTable';
import DeviceTabs from 'components/device-manager/DeviceTabs';
import { deviceManagerAPI } from 'api';
import {
    API_URL_PATH_DM_DEVICE_LIST,
    API_URL_PATH_DM_DEVICE,
    API_URL_PATH_DM_SYNC_DEVICES,
} from 'constants/urls';
import { Asset } from 'types/custom';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import useMutationWithReactQuery from 'hooks/useMutationWithReactQuery';
import { AxiosResponse } from 'axios';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

const DeviceManagerContext = createContext<{
  allDevices: Asset[];
  deviceLoading: boolean;
  deviceError: Error | null;
  setSelectedDevices: (asset: Asset[]) => void;
  selectedDevices: Asset[];
  getAllDevices: (options?: RefetchOptions) => Promise<QueryObserverResult<{ items: Asset[] }, Error>>; 
  activeTabId: string;
  setActiveTabId: (state: string) => void;
  refetchDeviceDetails: (options?: RefetchOptions) => Promise<QueryObserverResult<Asset, Error>>; 
  deviceResponse: Asset | null;
  deviceStatus: string;
  deviceFetching: boolean;
  syncDevices: () => Promise<AxiosResponse>;
  isSyncingDevices: boolean;
}>({
    allDevices: [],
    deviceLoading: false,
    deviceError: null,
    setSelectedDevices: () => {},
    selectedDevices: [],
    getAllDevices: async (_options?: RefetchOptions) => {
        return {} as QueryObserverResult<{ items: Asset[] }, Error>;
    },
    activeTabId: '',
    setActiveTabId: () => {},
    refetchDeviceDetails: async (_options?: RefetchOptions) => {
        return {} as QueryObserverResult<Asset, Error>;
    },
    deviceResponse: null,
    deviceStatus: '',
    deviceFetching: false,
    syncDevices: async () => {
        return {} as AxiosResponse
    },
    isSyncingDevices: false,
});

export const useDeviceManagerContext = () => useContext(DeviceManagerContext);

const DeviceListPage = () => {
    const [selectedDevices, setSelectedDevices] = useState<Asset[]>([]);
    const [activeTabId, setActiveTabId] = useState<string>('details');

    const { setNotification } = usePageLayoutContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.action) {
            setNotification([
                {
                    type: 'success',
                    content: location.state.message,
                },
            ]);

            navigate(location.pathname, {});
        }
    }, [location?.state?.action]);

    const {
        data: devices,
        error: deviceError,
        isLoading: deviceLoading,
        refetch: getDevices,
        isFetching: deviceFetching
    } = useFetchWithReactQuery<{
        items: Asset[]
    }>(
        {
            axiosInstance: deviceManagerAPI,
            url: API_URL_PATH_DM_DEVICE_LIST,
            key: 'devices',
        },
    );

    //get device details
    const {
        data: deviceResponse,
        status: deviceStatus,
        refetch: refetchDeviceDetails,
    } = useFetchWithReactQuery<Asset>(
        {
            axiosInstance: deviceManagerAPI,
            key: 'device-details',
            url: `${API_URL_PATH_DM_DEVICE}/${selectedDevices[0]?.name}`,
            enabled: false,
        },
    );
    
    const {
        mutateAsync: syncDevices,
        isPending: isSyncingDevices 
    } = useMutationWithReactQuery(
        {
            api: deviceManagerAPI,
            url: API_URL_PATH_DM_SYNC_DEVICES,
            method: "POST",
        },
    );

    return (
        <DeviceManagerContext.Provider
            value={{
                deviceLoading,
                allDevices: devices?.items || [],
                deviceError,
                setSelectedDevices,
                selectedDevices,
                activeTabId,
                setActiveTabId,
                refetchDeviceDetails,
                deviceResponse,
                deviceStatus,
                getAllDevices: getDevices,
                deviceFetching,
                isSyncingDevices,
                syncDevices: () => syncDevices({}),
            }}
        >
            <ColumnLayout variant='text-grid'>
                <DeviceTable />
            </ColumnLayout>

            {devices && (
                <>
                    <Box padding={{ top: 'm' }}/>
                    <ColumnLayout variant='text-grid'>
                        <DeviceTabs selectedDevices={selectedDevices} />
                    </ColumnLayout>
                </>
            )}
        </DeviceManagerContext.Provider>
    );
};

export default DeviceListPage;
