import { SimpleTreeView } from "@mui/x-tree-view";
import { useEffect, useState } from 'react';
import { Alert, AlertTitle, CircularProgress } from '@mui/material';

import useFetch from 'hooks/useFetch';
import { deviceManagerAPI } from 'api';
import LocationItem from './LocationItem';
import { Asset, LocationNode } from "types/custom";
import { buildSelectedPath } from "./utils";
import { API_URL_PATH_DM_DEVICE_LIST, API_URL_PATH_LOCATIONS_HIERARCHY } from 'constants/urls';
import { useDashboardContext } from "providers/DashboardProvider";
import { colors } from "../../../pages/dashboard/dashboard-page/utils";
import { makeNodeFromDevice } from "../hierarchy/utils";
import useFetchWithReactQuery from "hooks/useFetchWithReactQuery";

const LocationTree = () => {
  const {
    setSelectedPath,
    setSelectedNode,
    setChartDevices,
    chartDevices,
    setSelectedMeasurements,
  } = useDashboardContext();

  const [locations, setLocations] = useState<LocationNode>();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandWith, setExpandWith] = useState<string | null>(null);

  const { data: hierarchyLocation, isLoading: isHierarchyLocationLoading } = useFetchWithReactQuery<LocationNode[]>({
    axiosInstance: deviceManagerAPI,
    url: API_URL_PATH_LOCATIONS_HIERARCHY,
    key: 'locations-hierarchy',
  });

  const { fetchData: getDevices, loading: isDevicesLoading } = useFetch(
    {
      axiosInstance: deviceManagerAPI,
      method: 'GET',
    },
    { manual: true }
  );

  const handleNodeClick = (node: LocationNode) => {
    if (!hierarchyLocation) return;
    setSelectedNode(node);

    if (node?.title?.toLowerCase() === 'building') {
      const path = buildSelectedPath(`buildingId=${node?.id}`, node?.parent || '', node?.parentTitle || '', hierarchyLocation);
      setSelectedPath(path);
    }

    if (node?.title?.toLowerCase() === 'room') {
      const path = buildSelectedPath(`roomId=${node?.id}`, node?.parent || '', node?.parentTitle || '', hierarchyLocation);
      setSelectedPath(path);

      if (!node?.isRendered) {
        getDevices(`${API_URL_PATH_DM_DEVICE_LIST}?${path}`).then((sensors) => {
          const assets: Asset[] = sensors?.data?.items || [];
          if (assets.length > 0) {
            node.children = assets.map(makeNodeFromDevice);
            setExpandWith(`${node.id}room`);
          }
        });
        node.isRendered = true;
      }
    }

    if (node.isDevice) {
      const availableColors = colors.filter(color => !chartDevices.some(device => device.color === color));
      setChartDevices([{ ...node, color: availableColors[0] }]);
      setSelectedMeasurements(new Map());
    }
  };

  useEffect(() => {
    // This is used to ensure expanded will always have the latest available value
    if (expandWith) setExpanded([...expanded, expandWith]);
  }, [expandWith]);

  useEffect(() => {
    if (hierarchyLocation && hierarchyLocation.length > 0) {
      setLocations({
        id: 'regions',
        name: 'Regions',
        title: '0',
        children: hierarchyLocation,
      });

      //get all 1st child node id's to expand by default
      let expandedArr = ['regions0'];
      let stack = [];
      let node = [];

      stack.push(hierarchyLocation[0].children);
      expandedArr = [
        ...expandedArr,
        hierarchyLocation[0]?.id + hierarchyLocation[0]?.title,
      ];

      while (stack.length > 0) {
        node = stack.pop();

        if (node[0]?.children && node[0].children?.length > 0) {
          expandedArr = [...expandedArr, node[0]?.id + node[0].title];

          stack.push(node[0].children);
        }
      }

      setExpanded(expandedArr);
    }
  }, [hierarchyLocation]);

  return (
    <>
      {!locations?.children && !isHierarchyLocationLoading && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          No data available.
        </Alert>
      )}

      {locations &&
        <SimpleTreeView
          onExpandedItemsChange={(_event, nodeIds) => setExpanded(nodeIds)}
          expandedItems={expanded}
          itemChildrenIndentation={8}
        >
          <LocationItem
            key={locations.id + locations.title}
            nodes={locations}
            getDevicesLoading={isDevicesLoading}
            handleNodeClick={handleNodeClick} />
        </SimpleTreeView>
      }
      {isHierarchyLocationLoading && <CircularProgress style={{ color: 'black' }} />}
    </>

  );
};

export default LocationTree;